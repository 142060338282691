import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import Image from "gatsby-image"
import SEO from "../../components/seo"
import Layout from "../../components/Layout"

/* Styles */
import "../../styles/pages/the-hybrid.scss"

const TechPage = () => {
  const { bgTech, bgMobileTech, logoTech } = useStaticQuery(
    graphql`
      query {
        bgTech: file(relativePath: { eq: "the-hybrid/tech/tech-bg.jpg" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bgMobileTech: file(
          relativePath: { eq: "the-hybrid/tech/mobile-bg-tech.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoTech: file(relativePath: { eq: "the-hybrid/tech/tech-logo.png" }) {
          childImageSharp {
            fluid(quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageBgTech = bgTech.childImageSharp.fluid
  const imgBgMobileTech = bgMobileTech.childImageSharp.fluid
  const imgLogoTech = logoTech.childImageSharp.fluid

  // Background-Image Breakpoints
  const sources = [
    imgBgMobileTech,
    {
      ...imageBgTech,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <Layout bgImage={sources}>
      <SEO
        title="tech"
        keywords={[
          `Agencia`,
          `Transformacion Digital`,
          `The Hybrid`,
          `Growth`,
          `Rosario`,
          `Innovación`,
        ]}
      />
      <section id="tech-page" className="the-hybrid-inner-pages mt-lg-5">
        <Container>
          <Row>
            <Col className="d-none d-lg-block pt-5" xs={12} lg={2}>
              <p className="text-white mt-4">
                <Link
                  className="text-primary text-decoration-none"
                  to="/the-hybrid"
                >
                  _thehybrid
                </Link>
                {`>tech`}
              </p>
            </Col>
            <Col xs={12} lg={4}>
              <div className="inner-logo-images">
                <Image className="img-fluid" fluid={imgLogoTech} />
              </div>
            </Col>
            <Col className="" xs={12} lg={6}>
              <p className="text-content text-white">
                No hemos sido los primeros, pero amamos lo que hacemos y con
                tecnología e innovación logramos casi cualquier cosa.
              </p>
              <p className="text-content text-white">
                Tenemos la oportunidad de hacer lo que nos encanta y por eso
                intentamos que sea excelente. Innovar no es para todos, innovar
                es para quien entiende que con tecnología logramos
                transformarnos y transformar nuestra compañía o equipo, innovar
                ya no es una opción es parte dell ADN del futuro, innovación
                basada en tecnología. Cubrimos áreas de Desarrollo de Software,
                Big Data, Automation, AI, Transformación Digital, Procesos,
                Productos Digitales, Startup, etc.
              </p>
              <p className="text-content text-white">
                Un socio estratégico que posee la experiencia aplicada a la
                tecnología es clave para el futuro. La innovación nos distingue
                de los demás.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default TechPage
